.navbar {
  background-color: #fff;
}
.navbar-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 10px 25px;
}
.marquee-text {
  font-size: 29px;
  font-weight: 600;
  margin: 60px 0px;
  color: #f02d34;
}
.marquee {
  position: relative;
  height: 400px;
  width: 100%;
  overflow-x: hidden;
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 15s linear infinite;
  width: 180%;
}
.track:hover {
  animation-play-state: paused;
}
@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

span.text-red {
  -webkit-text-stroke: 1px #f02d34;
  margin-left: 6px;
}
.logo {
  color: gray;
  font-size: 18px;
}
.cart-icon {
  font-size: 25px;
  color: gray;
  cursor: pointer;
  position: relative;
  transition: transform 0.4s ease;
  border: none;
  background-color: transparent;
}
.cart-icon:hover {
  transform: scale(1.1, 1.1);
}
.cart-item-qty {
  position: absolute;
  top: -14px;
  left: 16px;
  height: 25px;
  width: 25px;
  background-color: #f02d34;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  border-radius: 50%;
  font-size: 14px;
}

.navbar-container .login-btn {
  background-color: dodgerblue;
  color: #fff;
  border: none;
  padding: 2px 15px;
  font-size: 14px;
  border-radius: 15px;
  display: flex;
  align-items: center;
}
