.hero-banner-container {
  padding: 100px 40px;
  background-color: #dcdcdc;
  border-radius: 15px;
  position: relative;
  height: 500px;
  line-height: 0.9;
  width: 100%;
}
.hero-banner-container .beats-solo {
  font-size: 20px;
  color: #000;
}
.hero-banner-container button {
  border-radius: 5px;
  padding: 12px 30px;
  background-color: #f02d34;
  color: white;
  border: none;
  margin-top: 40px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  z-index: 10000 !important;
}

.hero-banner-container h3 {
  font-size: 4rem;
  margin-top: 4px;
  font-weight: bold;
  color: #000;
}
.hero-banner-container h1 {
  color: white;
  font-size: 10em;
  margin-left: -20px;
  text-transform: uppercase;
  font-weight: bolder;
}
.hero-banner-image {
  position: absolute;
  top: 0%;
  right: 20%;
  width: 450px;
  height: 450px;
}

.desc {
  position: absolute;
  right: 10%;
  bottom: 5%;
  width: 300px;
  line-height: 1.3;
  display: flex;
  flex-direction: column;
  color: #324d67;
}
.desc p {
  color: #5f5f5f;
  font-weight: 100;
  text-align: end;
}
.desc h5 {
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 16px;
  /* color: black; */
  align-self: flex-end;
}

.footer-banner-container {
  padding: 100px 40px;
  background-color: #f02d34;
  border-radius: 15px;
  position: relative;
  height: 400px;
  line-height: 1;
  color: white;
  width: 100%;
  margin-top: 120px;
}

.footer-banner-container .banner-desc {
  display: flex;
  justify-content: space-between;
}
.footer-banner-container .banner-desc button {
  border-radius: 15px;
  padding: 10px 16px;
  background-color: white;
  color: red;
  border: none;
  margin-top: 40px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}
.footer-banner-container .banner-desc .left h3 {
  font-weight: 900;
  font-size: 80px;
  margin-left: 25px;
}
.footer-banner-container .banner-desc .left p {
  margin: 18px;
}
.footer-banner-image {
  position: absolute;
  /* top: -35%;
    left: 8%; */
  top: -25%;
  left: 25%;
}
.footer-banner-container .banner-desc .right {
  line-height: 1.4;
}
.footer-banner-container .banner-desc .right h3 {
  font-weight: 800;
  font-size: 60px;
}
.footer-banner-container .banner-desc .right p {
  font-size: 18px;
}
.footer-banner-container .banner-desc .right .company-desc {
  font-size: 14px;
  font-weight: 300;
}
