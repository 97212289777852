/* TAILWIND CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* TAILWIND CSS */

/* Hide scrollbar for webkit browsers */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for all other browsers */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-decoration {
  text-decoration: dashed 1px solid gray;
}

.container-width {
  max-width: 1400px;
  margin: auto;
  padding: 15px;
}

/* ============================ */

html,
body,
* {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  width: 0px;
}

a {
  color: inherit;
  text-decoration: none;
}

.main-container {
  max-width: 1400px;
  margin: auto;
  width: 100%;
}
.layout {
  padding: 10px;
}

.products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
  margin-top: 20px;
  width: 100%;
}

.cart-wrapper {
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  right: 0;
  top: 0;
  transition: all 1s ease-in-out;
}

.cart-container {
  overflow-y: auto;
  height: 100vh; /* Ensures full-height scrolling */
  max-height: 100vh; /* Prevents container from growing taller than the viewport */
  width: 600px;
  background-color: white;
  float: right;
  padding: 40px 10px;
}

.cart-heading {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  gap: 2px;
  margin-left: 10px;
  border: none;
  background-color: transparent;
}

.cart-heading .heading {
  margin-left: 10px;
  color: #000;
}
.cart-num-items {
  margin-left: 10px;
  color: #f02d34;
}
.empty-cart {
  margin: 40px;
  text-align: center;
}
.empty-cart h3 {
  font-weight: 600;
  font-size: 20px;
}
.cancel {
  cursor: pointer;
}
.checkout-product-container {
  margin-top: 15px;
  padding: 20px 10px;
}
.product-container {
  margin-top: 15px;
  overflow: auto;
  max-height: 70vh;
  padding: 20px 10px;
}
.product {
  display: flex;
  gap: 30px;
  padding: 20px;
}
.product .cart-product-image {
  width: 180px;
  height: 150px;
  border-radius: 15px;
  background-color: #ebebeb;
  object-fit: cover;
}
.item-desc .flex {
  display: flex;
  justify-content: space-between;
  width: 350px;
  color: #324d67;
}
.item-desc .bottom {
  margin-top: 60px;
}
.flex h5 {
  font-size: 24px;
}
.flex h4 {
  font-size: 20px;
}
.total {
  display: flex;
  justify-content: space-between;
}
.total h3 {
  font-size: 22px;
  color: #1f1f1f;
}
.remove-item {
  font-size: 24px;
  color: #f02d34;
  cursor: pointer;
  background: transparent;
  border: none;
}
.cart-bottom {
  width: 100%;
  padding: 10px 65px;
}

.btn-container {
  width: 80%;
  margin: auto;
}
.btn {
  margin-top: 20px;
  border-radius: 10px;
  border: none;
  text-transform: uppercase;
  background-color: #f02d34;
  color: #fff;
  cursor: pointer;
  transform: scale(1, 1);
  transition: transform 0.5s ease;
  padding: 0 25px;
}
.btn:hover {
  transform: scale(1.1, 1.1);
}
.product-detail-container {
  display: flex;
  gap: 40px;
  color: #324d67;
}
.product-detail-container .image-info {
  width: 40%;
}

.product-detail-image {
  border-radius: 15px;
  background-color: #ebebeb;
  object-fit: cover;
  width: 400px;
  height: 400px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.product-detail-image:hover {
  background-color: #f02d34;
}
.small-images-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}
.small-image {
  object-fit: cover;
  border-radius: 8px;
  background-color: #ebebeb;
  width: 70px;
  height: 70px;
  cursor: pointer;
}

.selected-image {
  background-color: #f02d34;
}
.reviews {
  color: #f02d34;
  margin-top: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
}
.reviews div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.product-detail-desc h1 {
  font-weight: bold;
  margin-top: 10px;
  font-size: 30px;
}

.product-detail-desc h4 {
  font-weight: bold;
  margin-top: 10px;
}
.product-detail-desc p {
  margin-top: 10px;
}
.reviews p {
  color: #324d67;
  margin-top: 0px;
}
.product-detail-desc .price {
  font-weight: 700;
  font-size: 26px;
  margin-top: 10px;
  color: #f02d34;
}
.product-detail-desc .price .regular-price {
  margin-right: 15px;
  text-decoration: line-through;
}
.product-detail-desc .coint-info {
  background-color: #059668;
  color: #fff;
  font-weight: 600;
  padding: 2px 20px;
  border-radius: 5px;
}

.price .old-price,
.product-price .old-price,
.price .old-price {
  color: gray;
  text-decoration: line-through;
}
.product-detail-desc .quantity {
  display: flex;
  gap: 20px;
  margin-top: 10px;
  align-items: center;
}
.product-detail-desc .buttons {
  display: flex;
  gap: 30px;
  margin-top: 20px;
}
.buttons .add-to-cart {
  padding: 10px 20px;
  border: 1px solid #f02d34;
  font-size: 18px;
  font-weight: 500;
  background-color: white;
  color: #f02d34;
  cursor: pointer;
  width: 200px;
  transform: scale(1, 1);
  transition: transform 0.5s ease;
}
.buttons .add-to-cart:hover {
  transform: scale(1.1, 1.1);
}
.buttons .buy-now {
  width: 200px;
  padding: 10px 20px;
  background-color: #f02d34;
  color: white;
  border: none;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transform: scale(1, 1);
  transition: transform 0.5s ease;
}
.buttons .buy-now:hover {
  transform: scale(1.1, 1.1);
}
.quantity-desc {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
}
.quantity-desc button,
span {
  font-size: 16px;
  padding: 6px 12px;
  cursor: pointer;
}
.quantity-desc .minus {
  border-right: 1px solid gray;
  color: #f02d34;
}
.quantity-desc .num {
  border-right: 1px solid gray;
  font-size: 20px;
}
.quantity-desc .plus {
  color: rgb(49, 168, 49);
}

.maylike-products-container {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}
.max-qty {
  font-weight: 500;
  color: #f02d34;
}
.success-wrapper,
.cancel-wrapper {
  background-color: white;
  min-height: 60vh;
}
.success,
.cancel {
  width: 1000px;
  margin: auto;
  margin-top: 160px;
  background-color: #dcdcdc;
  padding: 50px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.success .icon {
  color: green;
  font-size: 40px;
}
.success h2 {
  text-transform: capitalize;
  margin-top: 15px 0px;
  font-weight: 900;
  font-size: 40px;
  color: #324d67;
}
.success .email-msg {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
.cancel p {
  font-size: 20px;
  font-weight: 600;
}
.success .description {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin: 10px;
  margin-top: 30px;
}
.success .description .email {
  margin-left: 5px;
  color: #f02d34;
}
.product-max-qty {
  margin-top: 10px;
}

@media screen and (max-width: 800px) {
  .hero-banner-container {
    height: 560px;
  }
  .hero-banner-image {
    width: 77%;
    height: 62%;
    top: -2%;
    right: -6%;
  }
  .footer-banner-container {
    height: 560px;
    margin-top: 80px;
  }
  .footer-banner-image {
    width: 77%;
    left: 30%;
    top: 6%;
    height: 56%;
  }
  .footer-banner-container .banner-desc .left h3 {
    font-weight: 900;
    font-size: 50px;
    margin-left: 5px;
  }
  .footer-banner-container .banner-desc .left p {
    margin: 18px;
  }
  .footer-banner-container .banner-desc .right h3 {
    font-size: 45px;
  }
  .footer-banner-container .banner-desc .right p {
    font-size: 18px;
  }
  .footer-banner-container .banner-desc .right .company-desc {
    font-size: 14px;
  }
  .footer-banner-container .banner-desc {
    flex-wrap: wrap;
    gap: 20px;
  }
  .hero-banner-container {
    line-height: 1.3;
  }
  .hero-banner-container h1 {
    font-size: 50px;
  }
  .hero-banner-container h3 {
    font-size: 40px;
  }
  .hero-banner-container button {
    margin-top: 90px;
  }
  .desc {
    bottom: 60px;
  }
  .product-detail-container {
    flex-wrap: wrap;
  }
  .product-detail-container .product-detail-image {
    width: 350px;
    height: 350px;
  }
  .cart-container {
    width: 415px;
    padding: 4px;
  }
  .cart-heading {
    margin-top: 35px;
  }
  .product-container {
    margin-top: 10px;
  }
  .product {
    padding: 20px 5px;
  }
  .product .cart-product-image {
    width: 25%;
    height: 25%;
  }
  .buttons .add-to-cart {
    width: 150px;
  }
  .buttons .buy-now {
    width: 150px;
  }
  .product-detail-container {
    margin: 20px;
  }

  .item-desc .flex {
    width: 200px;
  }
  .top {
    flex-wrap: wrap;
    gap: 10px;
  }
  .item-desc .bottom {
    margin-top: 30px;
  }
  .flex h5 {
    font-size: 16px;
    color: #324d67;
  }
  .flex h4 {
    font-size: 16px;
    color: black;
  }
  .cart-bottom {
    padding: 30px;
  }

  .cart-bottom .total h3 {
    font-size: 20px;
  }
  .track {
    animation: marquee 10s linear infinite;
    width: 550%;
  }
  .success-wrapper,
  .cancel-wrapper {
    min-height: 69vh;
  }
  .success,
  .cancel {
    width: 370px;
    margin-top: 100px;
    padding: 20px;
  }
  .success {
    height: 350px;
  }
  .success h2 {
    font-size: 17px;
  }
  .btn-container {
    width: 300px;
    margin: auto;
  }
}
